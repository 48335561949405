import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { getPostsFromQuery } from '../utils/post'
import SingleCard from '../cards/card'
import PostUtils from '../utils/post_utils'

const SimilarArticlesComponent = ({ title, posts }) => {
  return (
    <>
      <h2>{title}</h2>
      <div className="row mb-3">
        {posts.map((post, i) => (
          <SingleCard key={i} title={post.title} 
            img={post.featuredImage.childImageSharp.fluid}
            href={post.slug}
            desc={post.excerpt}
            />
        ))}
      </div>
    </>
  )
}

const similarPosts = (props) => (
  <StaticQuery
    query={graphql`
      query SimilarPosts {    
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
              }
              excerpt(pruneLength: 80)
              frontmatter {
                title
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
                categories
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { title, terms, termType, currentPostSlug, maxPosts } = props;
      const posts = getPostsFromQuery(data.posts);
      const matchedPosts = new PostUtils(posts, currentPostSlug, terms, termType, maxPosts)
        .getPosts()
      return (
        <SimilarArticlesComponent title={title} posts={matchedPosts} />
      )
      
    }}
  />
)

export default similarPosts;
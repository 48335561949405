import React from "react"
import { graphql, Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"
import Img from "gatsby-image"
import SimilarPostsByTerm from '../components/content_utils/similar_posts_by_term'
import LatestPosts from '../components/content_utils/latest_posts'
import { DiscussionEmbed } from "disqus-react"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  let tags = post.frontmatter.tags.map((tag) =>
    <Link to={`/tags/${kebabCase(tag)}`} key={tag.toString()} className="btn btn-xs btn-soft-secondary mb-1 mr-1">
      {tag}
    </Link>
  );
  let terms = [].concat(post.frontmatter.tags).concat(post.frontmatter.categories);

  const disqusConfig = {
    shortname: data.site.siteMetadata.disqus_shortname,
    config: { identifier: post.fields.slug, title: post.frontmatter.title },
  }

  return (
    <SingleLayout>
      <Seo
        title={post.frontmatter.title}
        article={true}
        image={featuredImgFluid.src}
        description={post.frontmatter.description || post.excerpt}
      />

      <div className="w-lg-60 mx-lg-auto">
        <div className="mb-2">
          <h1 className="h1">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </div>
      </div>

      <div className="border-top mb-4">
        <Img fluid={featuredImgFluid} 
          alt={post.frontmatter.title} 
          title={post.frontmatter.title}
        />
      </div>

      <div className="w-lg-60 mx-lg-auto">

        <section className="markdown-toc mb-4 w-100"
          dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
        />

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <div className="mt-5">
          {tags}
        </div>

        <DiscussionEmbed {...disqusConfig} />
      </div>

        <hr />
        
        <SimilarPostsByTerm 
          title="Similar Posts"
          terms={terms}
          termType="both"
          currentPostSlug={post.fields.slug} maxPosts="6" />
      
        <LatestPosts
          title="Latest Posts"
          currentPostSlug={post.fields.slug} 
          maxPosts="6" />

    </SingleLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        disqus_shortname
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      tableOfContents
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        categories
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
